<template>
<div class="targetHospitalAudit">
  <a-spin :indicator="indicator" :spinning="spinning">
    <div style="overflow: hidden;display: flex;justify-content: space-between">
    <div class="left_case">
      <h4 style="padding: 10px">病例模板</h4>
      <a-menu
          style="width: 100%"
          mode="inline"
          v-model="menu"
      >
        <a-menu-item
            v-for="(item,index) in CaseTemplates"
            :key="index"
            @click="leftChange(item.id)"
        >
          {{item.title}}
        </a-menu-item>
      </a-menu>
    </div>
    <div class="hospitalInfo">
      <div class="select_Info">
        <div>
          状态:&nbsp;
          <a-radio-group v-model="openRadio"  @change="openChange" button-style="solid">
            <a-radio-button value= 'PENDING' >
              待审核
            </a-radio-button>
            <a-radio-button value= 'AGREED' >
              已通过
            </a-radio-button>
            <a-radio-button value= 'REJECT' >
              已驳回
            </a-radio-button>
          </a-radio-group>
        </div>
        <div style="margin-left: 100px">
          姓名:  &nbsp;<a-input v-model="searchName" placeholder="输入姓名搜索" style="width: 120px" size="default" />&nbsp;&nbsp;&nbsp;
          <a-button style="margin-left: 10px" type="primary" @click="searchOk" >查询</a-button>
          <a-button style="margin-left: 10px"  @click="searchReset">重置</a-button>
        </div>
      </div>
      <div class="table_Info">
        <a-table :columns="columns" style="min-height: 500px" :data-source="InfoTable" :rowKey="record => record.rid"   :pagination="false"
        >
      <span slot="switch" slot-scope="text,item">
        <span style="display: flex;align-items: center"> <div :style="{'background': item.status === 'PENDING' ? '#0066CC' :item.status === 'AGREED' ?'#009933' : item.status === 'REJECT' ?'#CC0000':'','width':'8px','height':'8px','border-radius': '8px'}" > </div>
          &nbsp;{{item.status === 'PENDING' ? '待审批' :item.status === 'AGREED' ?'已通过' : item.status === 'REJECT' ?'已驳回':''}}</span>
      </span>
          <span slot="operate" slot-scope="text,item">
            <a   v-if="item.status === 'AGREED' || item.status === 'REJECT'" @click="check(item)">查看</a>
            <a   v-else @click="check(item)">审核</a>
      </span>
        </a-table>
        <div  style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
          <a-pagination v-model="pagination.current"
                        :total="pagination.total"
                        @change="paginationChange" />
        </div>
      </div>
    </div>
    </div>

    <a-drawer
        :title="state"
        width="600"
        :visible="auditShow"

        @close="auditClose"
    >
      <h3>注册信息</h3>
      <a-form-model
          ref="audit"
          :model="auditForm"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="身份" prop="type">
          <a-select v-model="auditForm.type"  placeholder="选择身份" @change="typeChange">
            <a-select-option v-for="list in typeInfo" :key="list.value" :value="list.value">
              {{ list.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="姓名" prop="name">
          <a-input v-model="auditForm.name" />
        </a-form-model-item>
        <a-form-model-item prop="hospitalName" label="医院" v-if="auditForm.type !== 1006 & auditForm.type !== 1007 & auditForm.type !== 1005">
          <a-input v-model="auditForm.hospitalName" />
        </a-form-model-item>
        <a-form-model-item prop="hospitalOffices" label="科室" v-if="auditForm.type !== 1006 & auditForm.type !== 1007 & auditForm.type !== 1005">
          <a-input v-model="auditForm.hospitalOffices" />
        </a-form-model-item>
        <a-form-model-item prop="jobTitle" label="职称" v-if="auditForm.type !== 1006 & auditForm.type !== 1007 & auditForm.type !== 1005">
          <a-input v-model="auditForm.jobTitle" />
        </a-form-model-item>
        <a-form-model-item prop="school" label="学校" v-if="auditForm.type === 1005">
          <a-input v-model="auditForm.school" />
        </a-form-model-item>
        <a-form-model-item prop="specialty" label="专业" v-if="auditForm.type === 1005">
          <a-input v-model="auditForm.specialty" />
        </a-form-model-item>
        <a-form-model-item prop="education" label="学历" v-if="auditForm.type === 1005">
          <a-select v-model="auditForm.education"  placeholder="选择学历" @change="BAChange">
            <a-select-option v-for="list in BA" :key="list" :value="list">
              {{ list }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="workUnit" label="单位" v-if="auditForm.type === 1006 || auditForm.type === 1007">
          <a-input v-model="auditForm.workUnit" />
        </a-form-model-item>
        <a-form-model-item prop="post" label="职务" v-if="auditForm.type === 1006 || auditForm.type === 1007">
          <a-input v-model="auditForm.post" />
        </a-form-model-item>
      </a-form-model>
      <h3>认证信息</h3>
      <span v-if=" openSearch === 'REJECT'" style="color: red">驳回原因:{{auditForm.remark}}</span>
      <div style="margin: 20px">
        <img class="auditPic"  :src="auditForm.employeeCardUrl" alt="" v-if="auditForm.employeeCardUrl">
        <img class="auditPic"  :src="auditForm.practiceLicenseUrl" alt="" v-if="auditForm.practiceLicenseUrl">
        <img class="auditPic"  :src="auditForm.nvqUrl" alt="" v-if="auditForm.nvqUrl">
      </div>
      <div
          :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginRight: 10px" @click="()=>{this.auditShow = false}">
          取消
        </a-button>
        <a-button v-if="stateOpen === 1" style="marginRight:  10px" type="danger" @click="()=>this.rejectedShow = true">
          驳回
        </a-button>
        <a-button v-if="stateOpen === 1" style="marginRight: 10px" type="primary" @click="passOk">
          通过
        </a-button>
      </div>
    </a-drawer>
    <a-modal v-model="rejectedShow" title="驳回原因" okType="danger" okText="驳回" @ok="rejectedOk">
      <a-textarea v-model="auditForm.remark">

      </a-textarea>

    </a-modal>
  </a-spin>
</div>
</template>

<script>
import {getAuditId, putAudit} from "@/service/memberImport_api";
import {getCaseHospital, getCaseHospitalInfo} from "@/service/CaseTemplate_api";

export default {
  name: "targetHospitalAudit",
  data(){
    return{
      spinning: false,
      auditShow:false,
      rejectedShow:false,
      menu:[0],
      CaseTemplates:[],
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      searchName:'',
      state:'',
      openRadio:'PENDING',
      auditForm:{

      },
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      typeInfo:[
        {
          value:1001,
          label:'医生(1001)'
        },
        {
          value:1002,
          label:'护士(1002)'
        },
        {
          value:1003,
          label:'医技(1003)'
        },
        {
          value:1004,
          label:'药师(1004)'
        },
        {
          value:1005,
          label:'医学生(1005)'
        },
        {
          value:1006,
          label:'其他医务从业者(1006)'
        },
        {
          value:1007,
          label:'非医务从业者(1007)'
        },
      ],
      BA:[
        '高中/中专','大专','本科','硕士','博士'
      ],
      columns:[
        {
          title: '姓名',
          dataIndex: 'name',
        },
        {
          title: '医院',
          dataIndex: 'hospitalName',
        },
        {
          title: '科室',
          dataIndex: 'hospitalOffices',
        },
        {
          title: '职称',
          dataIndex: 'jobTitle',
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'switch' },
        },
        {
          title: '提交时间',
          dataIndex: 'createdTime',

        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      InfoTable:[],
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }],
        type: [
          { required: true, message: '请选择身份', trigger: 'change' }],
        post: [
          { required: true, message: '请输入职务', trigger: 'blur' }],
        workUnit: [
          { required: true, message: '请输入单位', trigger: 'blur' }],
        education: [
          { required: true, message: '请选择学历', trigger: 'change' }],
        specialty: [
          { required: true, message: '请输入专业', trigger: 'blur' }],
        school: [
          { required: true, message: '请输入学校', trigger: 'blur' }],
        jobTitle: [
          { required: true, message: '请输入职称', trigger: 'blur' }],
        hospitalOffices: [
          { required: true, message: '请输入科室', trigger: 'blur' }],
        hospitalName: [
          { required: true, message: '请输入医院', trigger: 'blur' }],
      },
      stateOpen:0,
      caseId:0,
      openSearch:'',
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"目标医院认证管理")
  },
   async mounted() {
    await this.getHospitalMenu()
     this.caseId = this.CaseTemplates[0].id
    await this.getHospitalInfo(this.caseId)
  },
  methods:{
    async getHospitalMenu(){
      const Info =await getCaseHospital()
      if (Info.code === 0 ){
        this.CaseTemplates = Info.data
      }
    },
    async getHospitalInfo(id,pageNum,status,name){
      this.spinning = true
      const Info =await getCaseHospitalInfo(id,pageNum,status,name)
      if (Info.code === 0 ){
        this.InfoTable = Info.data.wyYsR
        this.pagination.total=Info.data.count
      }
      this.spinning = false
    },
    leftChange(id){
      this.caseId = id
      this.getHospitalInfo(id,1)
      this.pagination.current = 1
      this.openRadio = 'PENDING'
    },
    typeChange(e){
      this.auditForm.type = e
    },
    //学历选择
    BAChange(e){
      this.auditForm.education = e
    },
    //分页跳转
    paginationChange(e){
      this.getHospitalInfo(this.caseId,e,this.openSearch,this.searchName)
    },
    //搜索重置
    searchReset(){
      this.getHospitalInfo(this.caseId,1)
      this.openRadio='PENDING'
      this.openSearch = ''
      this.searchName = ''
      this.searchHospital = ''
      this.pagination.current = 1
    },
    //确认搜索
    searchOk(){
      this.getHospitalInfo(this.caseId,1,this.openSearch,this.searchName)
      this.pagination.current = 1
    },
    //状态搜索
    openChange(e){
      switch (e.target.value){
        case 'PENDING':
          this.getHospitalInfo(this.caseId,1,'PENDING')
          this.pagination.current = 1
          this.openSearch = 'PENDING'
          break;
        case 'AGREED' :
          this.getHospitalInfo(this.caseId,1,'AGREED',)
          this.openSearch = 'AGREED'
          this.pagination.current = 1
          break;
        case 'REJECT':
          this.getHospitalInfo(this.caseId,1,'REJECT',)
          this.openSearch = 'REJECT'
          this.pagination.current = 1
          break;
      }
    },
    //审核按钮
    async check(item){
      if (item.status === 'AGREED'){
        this.state = '查看'
        this.stateOpen = 0
      }else {
        this.state = '审核'
        this.stateOpen = 1
      }
      this.audtId = item.rid
      const Info = await getAuditId(item.rid)
      this.auditForm = Info.data[0]
      this.auditShow = true
    },
    auditClose(){
      this.auditShow = false
      this.auditForm = {}
    },
    //审核通过
    async passOk(){
      this.$refs.audit.validate(async valid =>{
        if (valid){
          const form = this.auditForm
          if (this.auditForm.type !== 1006 & this.auditForm.type !== 1007 && this.auditForm.type !== 1005){
            this.putForm = {
              type: form.type,
              name: form.name,
              hospitalName: form.hospitalName,
              hospitalOffices: form.hospitalOffices,
              jobTitle: form.jobTitle,
              status: "AGREED"
            }
          }

          if (this.auditForm.type === 1005 ){
            this.putForm={
              type: form.type,
              name: form.name,
              school:form.school,
              specialty:form.specialty,
              education:form.education,
              status: "AGREED"
            }
          }
          if (this.auditForm.type === 1006 || this.auditForm.type === 1007 ){
            this.putForm={
              type: form.type,
              name: form.name,
              workUnit:form.workUnit,
              post:form.post,
              status: "AGREED"
            }
          }
          const Info =await putAudit(this.audtId,this.putForm)
          if (Info.code===0){
            this.$message.success('已通过')
            await this.getHospitalInfo(this.caseId,this.pagination.current,this.openSearch)
            this.$refs.audit.resetFields();
            this.auditShow= false;
            this.auditForm = {}
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //审核驳回
    async rejectedOk(){
      this.$refs.audit.validate(async valid =>{
        if (valid){
          const form = this.auditForm
          if (this.auditForm.type !== 1006 & this.auditForm.type !== 1007 && this.auditForm.type !== 1005){
            this.putForm = {
              type: form.type,
              name: form.name,
              hospitalName: form.hospitalName,
              hospitalOffices: form.hospitalOffices,
              jobTitle: form.jobTitle,
              remark : form.remark,
              status: "REJECT"
            }
          }

          if (this.auditForm.type === 1005 ){
            this.putForm={
              type: form.type,
              name: form.name,
              school:form.school,
              specialty:form.specialty,
              education:form.education,
              remark : form.remark,
              status: "REJECT"
            }
          }
          if (this.auditForm.type === 1006 || this.auditForm.type === 1007 ){
            this.putForm={
              type: form.type,
              name: form.name,
              workUnit:form.workUnit,
              post:form.post,
              remark : form.remark,
              status: "REJECT"
            }
          }
          const Info =await putAudit(this.audtId,this.putForm)
          if (Info.code===0){
            this.$message.success('已驳回')
            await this.getHospitalInfo(this.caseId,this.pagination.current,this.openSearch)
            this.$refs.audit.resetFields();
            this.rejectedShow = false;
            this.auditShow= false;
            this.auditForm = {}
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.auditPic{
  width: 90%;
  height: auto;
}
.targetHospitalAudit{
  margin: -15px -15px ;
  background-color: rgba(240, 242, 245, 1);
  position: relative;
   .left_case{
     width: 20%;
     background-color: white;
     height: 100%;
     margin-bottom: -1000px;
     padding-bottom: 1000px;
}
  .hospitalInfo{
    background-color: white;
    float: right;
    width: 79%;
    margin-bottom: -1000px;
    padding-bottom: 1000px;
    .select_Info{
      display: flex;
      margin: 20px;
    }
    .table_Info{
      margin: 20px;
    }
  }
}
</style>
